import { FC } from "react";
import { Pill } from "../../components/Pill/Pill";
import Grid from "@mui/material/Unstable_Grid2";
import { SkillsAlphabetized as SkillsArr } from "./utils";

export interface SkillsProps {
  className?: string;
}

export const Skills: FC<SkillsProps> = ({ className }) => {
  return (
    <div className="mx-2 my-5 w-full">
      <h2 className="font-bold ml-1">Skills</h2>
      <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
        {SkillsArr.map((item) => (
          <Grid className="flex" xs="auto" key={item.skill}>
            <Pill type={item.type} tooltipText={item.tooltipText}>
              {item.skill}
            </Pill>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};
