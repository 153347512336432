import { FC, useState } from "react";
import { RecContainer } from "../../components/RecContainer/RecContainer";
import {
  RecommendationType,
  RecommendationArr,
} from "../../components/RecContainer/utils";
import { Button } from "../../components/Button/Button";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";

export interface RecommendationsProps {
  className?: string;
}

export const Recommendations: FC<RecommendationsProps> = ({ className }) => {
  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = RecommendationArr.length;
  const handleNext = () => {
    setActiveStep((prevActiveStep) =>
      prevActiveStep + 1 === maxSteps ? 0 : prevActiveStep + 1
    );
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) =>
      prevActiveStep - 1 < 0 ? maxSteps - 1 : prevActiveStep - 1
    );
  };

  return (
    <div
      id="recommendations"
      className="bg-custom-white flex items-center p-10"
    >
      <Button onClick={handleBack}>
        <KeyboardArrowLeft />
      </Button>
      {RecommendationArr.map((item: RecommendationType, index) => (
        <div>
          {Math.abs(activeStep - index) < 1 ? (
            <RecContainer
              className="lg:flex-grow flex md:flex-row flex-col"
              key={item.recName}
              recName={item.recName}
              recommendation={item.recommendation}
              imgSrc={item.imgSrc}
              linkedIn={item.linkedIn}
              recRole={item.recRole}
            />
          ) : null}
        </div>
      ))}
      <Button onClick={handleNext}>
        <KeyboardArrowRight />
      </Button>
    </div>
  );
};
