export enum SkillType {
  frontend = "frontend",
  backend = "backend",
  other = "other",
}

export type SkillsObj = {
  skill: string;
  type: SkillType;
  tooltipText?: string;
};

const SkillsArr: SkillsObj[] = [
  {
    skill: "React",
    type: SkillType.frontend,
    tooltipText:
      "I have 3 years experience working in React. At PopSQL I frequently built components from scratch.",
  },
  {
    skill: "TypeScript",
    type: SkillType.frontend,
    tooltipText:
      "I have 2 years experience working with TypeScript including converting JavaScript files to TypeScript.",
  },
  {
    skill: "JavaScript",
    type: SkillType.frontend,
    tooltipText: "I have 3 years of professional JavaScript experience.",
  },
  {
    skill: "Apollo GraphQL",
    type: SkillType.frontend,
    tooltipText:
      "I have 3 years of experience with GraphQL. Though mostly in chartge of the frontend, I feel comfortable adding endpoints as needed to the backend.",
  },
  {
    skill: "Redux",
    type: SkillType.frontend,
    tooltipText:
      "I have 2 years experience using Redux as a state management tool for the frontend.",
  },
  {
    skill: "Tailwind",
    type: SkillType.frontend,
    tooltipText:
      "I have 2 years experience with Tailwind. This website is built with tailwind!",
  },
  {
    skill: "Ruby",
    type: SkillType.backend,
    tooltipText:
      "I have 3 years of explosure to Ruby, though not a lot of experience with it. I am looking for more backend growth wherever I land next!",
  },
  {
    skill: "Rails",
    type: SkillType.backend,
    tooltipText:
      "I have 3 years of exposure with Rails, including using the Rails console.",
  },
  {
    skill: "Java",
    type: SkillType.backend,
    tooltipText:
      "Java is the backend language I am most familiar with due to my schooling though I have no professional experience with it.",
  },
  {
    skill: "Datadog",
    type: SkillType.other,
    tooltipText:
      "I have 1 year of experience with Datadog. At PopSQL we used Datadog as one of our monitoring systems.",
  },
  {
    skill: "Linear",
    type: SkillType.other,
    tooltipText:
      "I have 2 years of experience with Linear, though have also used Pivotal Tracker and Jira for sprint planning. I would frequently write, point, and triage tickets since we didn't have a PM at PopSQL.",
  },
  {
    skill: "Intercom",
    type: SkillType.other,
    tooltipText:
      "At PopSQL I was on a bi-monthly on-call support rotation which involved communicating with customers and solving concerns via Intercom.",
  },
  {
    skill: "Figma",
    type: SkillType.other,
    tooltipText:
      "I have 3 years of experience using Figma for mocks and would frequently communicate with design via Figma for concerns or questions.",
  },
  {
    skill: "Docker",
    type: SkillType.other,
    tooltipText: "I have 3 years of experience using Docker.",
  },
  {
    skill: "AWS",
    type: SkillType.other,
    tooltipText:
      "I have 1 year of exposure to AWS and would love to have more of an opportunity to learn and dig into it.",
  },
  {
    skill: "Electron",
    type: SkillType.other,
    tooltipText:
      "I have 2 years experience building an Electron app in tandem with a webapp.",
  },
  {
    skill: "Github",
    type: SkillType.other,
    tooltipText: "I have 4 years experience with Github.",
  },
  {
    skill: "Notion",
    type: SkillType.other,
    tooltipText:
      "I use Notion both professionally and personally as documentation and notetaking.",
  },
  {
    skill: "Playwright",
    type: SkillType.frontend,
    tooltipText:
      "I have 1 year of experience using Playwright for frontend E2E tests.",
  },
];
export const SkillsAlphabetized = [...SkillsArr].sort((a, b) =>
  a.skill.localeCompare(b.skill)
);
