export type RecommendationType = {
  recName: string;
  recommendation: string;
  imgSrc: string;
  linkedIn: string;
  recRole: string;
};
export const RecommendationArr: RecommendationType[] = [
  {
    recName: "Justin Murray",
    recommendation:
      "I had the pleasure of working closely with Taylor for nearly two years as her manager at PopSQL. Taylor's inventive approach and her willingness to tackle tedious and complex challenges make her an exceptional asset to any engineering team.  Her commitment to enhancing customer experience shone through in her projects, notably the comprehensive overhaul of our chart configuration editor which markedly improved UX and boosted user satisfaction. With a deep understanding of React development and a precise touch with CSS, Taylor quickly became a go-to for our seasoned developers when faced with complex frontend issues. She frequently took the initiative to lead projects, ensuring smooth progress and collaboration, particularly when partnering with a senior backend engineer to create compelling full-stack product features. I have no reservations in saying that, given the opportunity and resources, I would not hesitate to work with Taylor again. Her potential is boundless, and I wholeheartedly recommend her for any role that seeks to harness her burgeoning talent and drive.",
    imgSrc: "./../images/RecHeadshots/JustinHeadshot.jpeg",
    linkedIn: "https://www.linkedin.com/in/murrayju/",
    recRole: "Tech Lead Manager",
  },
  {
    recName: "Dor Dan",
    recommendation:
      "Taylor joined my team as someone new to software development and was eager to learn and consistently leveled herself up. Very quickly, she was able to handle her own projects and provide valuable input that impacted the engineering decisions of the team. Taylor is also a great steward for the culture of your team, she enjoys getting to know everyone and is always looking to include everyone. Any team would be lucky to have Taylor on it!",
    imgSrc: "./../images/RecHeadshots/DorHeadshot.jpeg",
    linkedIn: "https://www.linkedin.com/in/dor-dan/",
    recRole: "Director of Engineering",
  },
  {
    recName: "Vic Fernández",
    recommendation:
      "Was lucky to work closely with Taylor on different projects. Her attention to detail, bias for action, and willingness to learn and adapt was an incredible asset to the team that ensured we shipped quality product.",
    imgSrc: "./../images/RecHeadshots/VicHeadshot.jpeg",
    linkedIn: "https://www.linkedin.com/in/victorhf/",
    recRole: "Designer",
  },
];
