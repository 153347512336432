import { FC } from "react";

export const Footer: FC = () => {
  return (
    <footer className="flex bg-space-cadet text-white justify-center text-center p-4">
      © Copyright 2024 Taylor Dunn <br />
      Built with React, MaterialUI, TailwindCSS
    </footer>
  );
};
