import { FC } from "react";
import { Navbar } from "../../components/Navbar/Navbar";
import { Footer } from "../../components/Footer/Footer";

export const Error: FC = () => (
  <>
    <Navbar />
    <div className="text-center w-full bg-space-cadet text-custom-white justify-center h-[calc(100vh-200px)]">
      You've hit a route that doesn't exist! You are either bad at spelling or
      I'm bad at coding. Either way, click the logo to get back to the fun!
    </div>
    <Footer />
  </>
);
