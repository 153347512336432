import { FC, useState } from "react";
import { Button, ButtonVariant } from "../Button/Button";
import { Link } from "react-router-dom";
import { PathConstants } from "../../routes/pathConstants";

export interface NavbarProps {
  className?: string;
}

export const Navbar: FC<NavbarProps> = ({ className }) => {
  const [logoSrc, setLogoSrc] = useState("./images/TDLogo.png");
  return (
    <header className="flex space-between bg-space-cadet text-white">
      <div className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
        <Link to={PathConstants.HOME}>
          <img
            src={logoSrc}
            onMouseOver={() => setLogoSrc("./images/TDLogoReverse.png")}
            onMouseLeave={() => setLogoSrc("./images/TDLogo.png")}
            alt="logo that says Taylor Dunn"
            width={200}
          />
        </Link>

        <nav className="md:mr-auto md:ml-4 md:py-1 md:pl-4 md:border-l md:border-gray-700	flex flex-wrap items-center text-base justify-center">
          <a href="#contact" className="mr-5 hover:text-custom-red">
            Contact
          </a>
          <a href="#recommendations" className="mr-5 hover:text-custom-red">
            Recommendations
          </a>
          {/* <Link
            to={PathConstants.RESUME}
            className="mr-5 hover:text-custom-red"
          >
            Resume
          </Link> */}
        </nav>
      </div>
    </header>
  );
};
