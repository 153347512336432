import { FC } from "react";
import { Navbar } from "../../components/Navbar/Navbar";
import { About } from "../About/About";
import { Recommendations } from "../Recommendations/Recommendations";
import { Contact } from "../Contact/Contact";

export const Home: FC = () => {
  return (
    <>
      <About />
      <Recommendations className="bg-custom-white" />
      <Contact />
    </>
  );
};
