import classNames from "classnames";
import React, { FC, MouseEvent } from "react";

export enum ButtonVariant {
  outline = "outline",
  green = "green",
}

export const VARIANT_CLASSES: Record<ButtonVariant, string> = {
  outline:
    "font-semibold text-gray-700 bg-custom-white hover:bg-gray-300 border-solid border hober:border-button-outline-hover",
  green: "text-white font-semibold bg-custom-green hover:bg-custom-blue",
};

export interface ButtonProps {
  children?: React.ReactNode;
  className?: string;
  isDisabled?: boolean;
  onClick?: (event: MouseEvent) => void;
  ref?: React.Ref<HTMLButtonElement>;
  //default green
  variant?: ButtonVariant;
  href?: string;
}

export const Button: FC<ButtonProps> = React.forwardRef(
  (props: ButtonProps, ref: React.ForwardedRef<HTMLButtonElement>) => {
    const {
      className,
      isDisabled = false,
      onClick,
      variant = ButtonVariant.green,
      children,
      href,
    } = props;

    const classes = classNames(
      "flex items-center justify-center p-1 br-2 rounded-sm",
      VARIANT_CLASSES[variant],
      className,
      {
        "opacity-50 pointer-events-none": isDisabled,
      }
    );

    return (
      <a href={href}>
        <button
          ref={ref}
          className={classes}
          disabled={isDisabled}
          onClick={onClick}
        >
          {children}
        </button>
      </a>
    );
  }
);
