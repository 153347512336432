import { FC, ReactNode } from "react";
import classNames from "classnames";
import { Tooltip } from "@mui/material";
import { SkillType } from "../../pages/Skills/utils";

export const TYPE_CLASSES: Record<SkillType, string> = {
  frontend: "bg-custom-red",
  backend: "bg-custom-blue",
  other: "bg-custom-green",
};

export interface PillProps {
  className?: string;
  type: SkillType;
  children: ReactNode;
  tooltipText?: string;
}

export const Pill: FC<PillProps> = ({
  className,
  type,
  children,
  tooltipText,
}) => {
  const classes = classNames(
    "flex rounded-lg p-1 m-1 text-white text-center hover:opacity-50 hover:cursor-default",
    TYPE_CLASSES[type],
    className
  );
  return (
    <Tooltip
      title={tooltipText}
      placement="top"
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: "grey-300",
            "& .MuiTooltip-arrow": {
              color: "common.black",
            },
          },
        },
      }}
    >
      <div className={classes}>{children}</div>
    </Tooltip>
  );
};
