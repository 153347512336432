import Layout from "./components/Layout/Layout";
import { About } from "./pages/About/About";
import { Contact } from "./pages/Contact/Contact";
import { Home } from "./pages/Home/Home";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { Resume } from "./pages/Resume/Resume";
import { Error } from "./pages/Error/Error";

export default function App() {
  const router = createBrowserRouter([
    {
      element: <Layout />,
      errorElement: <Error />,
      children: [
        {
          path: "/",
          element: <Home />,
        },
        {
          path: "/resume",
          element: <Resume />,
        },
      ],
    },
  ]);
  return <RouterProvider router={router} />;
}
