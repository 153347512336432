import { FC } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { CardActionArea, CardMedia, Typography } from "@mui/material";

export interface RecContainerProps {
  className?: string;
  recName?: string;
  recommendation?: string;
  imgSrc?: string;
  linkedIn?: string;
  recRole: string;
}

export const RecContainer: FC<RecContainerProps> = ({
  className,
  recName,
  recommendation,
  imgSrc,
  linkedIn,
  recRole,
}) => {
  return (
    <Card
      className={className}
      sx={{
        display: "flex",
        width: "full",
        margin: "12px",
        alignItems: "center",
      }}
    >
      <CardContent sx={{ textAlign: "center" }}>
        <div className="text-space-cadet text-lg font-semibold">{recName}</div>
        <div className="text-custom-green italic">{recRole}</div>
        <CardMedia
          component="img"
          image={imgSrc}
          alt="LinkedIn profile photo"
          className="p-2 flex"
          sx={{ objectFit: "contain", borderRadius: "20px", width: 200 }}
        />
        <CardActionArea>
          <a
            href={linkedIn}
            target="_blank"
            rel="noreferrer"
            className="text-custom-blue"
          >
            LinkedIn
          </a>
        </CardActionArea>
      </CardContent>
      <CardContent sx={{ width: 4 / 5 }}>
        <div>{recommendation}</div>
      </CardContent>
    </Card>
  );
};
