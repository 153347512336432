import { FC } from "react";
import Typewriter from "typewriter-effect";
import { Button, ButtonVariant } from "../../components/Button/Button";
import { Skills } from "../Skills/Skills";

export interface AboutProps {
  className?: string;
}

export const About: FC<AboutProps> = ({ className }) => {
  console.log(
    "~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~ \nI always love when people hide information in the console! \nSo hello. Welcome to the console fun facts! \nI've seen every season of Survivor \nI love fantasy football (Go Hawks!) \nI live in Utah but I hate the snow \n~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~"
  );
  return (
    <section id="about" className="bg-space-cadet">
      <div className="container mx-auto flex p-5 md:flex-row flex-col items-center text-white">
        <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:m-4 items-center text-center">
          <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium ">
            <Typewriter
              options={{
                strings: ["Teacher Turned Tech"],
                autoStart: true,
                loop: true,
              }}
            />
          </h1>
          <p className="mb-8 leading-relaxed">
            Hey there! I'm Taylor — a former high school math teacher turned
            software engineer. My journey from education to tech isn't just a
            career change — it's a fusion of skills that sets me apart. <br />
            <br />
            In my three years as an engineer, I've often been praised for
            embodying senior-level soft skills that I honed during my time
            teaching. I acquired a profound understanding of diverse minds,
            nurtured patience, and fostered empathy — lessons that seamlessly
            integrate into my current work. My ability to connect with
            individuals from various backgrounds and learning styles is a point
            of pride for me and makes me a valuable asset in a team setting.
            <br />
            <br />I now get to practice what I once taught: a dedication to
            continuous learning, a resilient problem-solving approach, and the
            belief that failures are stepping stones toward success. I firmly
            believe in the transformative power of feedback — it's an integral
            part of our growth. I look forward to working with a company who
            shares these values!
          </p>
          <div className="flex">
            <Button href="#contact">Work With Me</Button>
            {/* <Button
              className="ml-2"
              href="#projects"
              variant={ButtonVariant.outline}
            >
              See My Past Work
            </Button> */}
          </div>
        </div>
        <div className="lg:max-w-lg lg:w-full md:w-1/2">
          <img
            className="rounded-lg m-2 w-full"
            alt="hero"
            src="./images/Headshot.jpg"
            width={300}
          />
          <Skills />
          {/* <Avatar /> */}
        </div>
      </div>
    </section>
  );
};
