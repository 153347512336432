import { FC, useState } from "react";
import { Button } from "../../components/Button/Button";

export interface ContactProps {
  className?: string;
}

export const Contact: FC<ContactProps> = ({ className }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  function encode(data: any) {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      )
      .join("&");
  }

  function handleSubmit(e: any) {
    e.preventDefault();
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", name, email, message }),
    })
      .then(() => alert("Message sent!"))
      .catch((error) => alert(error));
  }

  const inputClassname =
    "w-full bg-custom-white rounded border border-custom-white focus:border-custom-green focus:ring-2 focus:ring-custom-green text-base outline-none text-space-cadet py-1 px-3 leading-8 transition-colors duration-200 ease-in-out";

  const labelClassname = "leading-7 text-sm text-custom-white";

  return (
    <section id="contact" className="relative bg-space-cadet">
      <div className="container px-5 py-10 mx-auto flex sm:flex-nowrap flex-wrap">
        <form
          data-netlify="true"
          name="contact"
          className="flex flex-col md:ml-auto w-full md:py-8 mt-8 md:mt-0"
        >
          <h2 className="text-custom-white sm:text-4xl text-3xl mb-1 font-medium title-font">
            Contact Me
          </h2>
          <p className="leading-relaxed mb-5 ml-1 text-custom-white">
            I am currently looking for a new position! Don't hesitate to reach
            out if you think I'd be a good fit!
          </p>
          <div className="relative mb-4">
            <label htmlFor="name" className={labelClassname}>
              Name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              className={inputClassname}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="relative mb-4">
            <label htmlFor="email" className={labelClassname}>
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              className={inputClassname}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="relative mb-4">
            <label htmlFor="message" className={labelClassname}>
              Message
            </label>
            <textarea
              id="message"
              name="message"
              className={inputClassname}
              onChange={(e) => setMessage(e.target.value)}
            />
          </div>
          <Button onClick={handleSubmit}>Submit</Button>
        </form>
      </div>
    </section>
  );
};
